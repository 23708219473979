import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLoading } from '@/composables/useLoading'
import type {
    AendereBemerkungAnDieLandesstelleRequest,
    MarkiereBeurkundungAlsVersendetRequest,
    MarkiereRechnungAlsVersendetRequest,
    NehmeOrdnungspruefungVorRequest,
    Zertifizierung
} from '@/js/model'
import { useZertifizierungService } from '@/services'

export const useZertifizierungStore = defineStore('zertifizierung', () => {
    const zertifizierungen = ref<Zertifizierung[]>([])
    const service = useZertifizierungService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const addOrReplaceZertifizierung = (zertifizierung: Zertifizierung): void => {
        const index = zertifizierungen.value.findIndex(
            (item) => item.zertifizierungId === zertifizierung.zertifizierungId
        )

        if (index !== -1) {
            zertifizierungen.value[index] = zertifizierung
        } else {
            zertifizierungen.value.push(zertifizierung)
        }
    }

    const getZertifizierungById = (id: string): Zertifizierung | null =>
        zertifizierungen.value.find((item) => item.zertifizierungId === id) || null

    const fetchZertifizierungById = async (id: string): Promise<void> => {
        await setLoading('fetch', async () => {
            addOrReplaceZertifizierung(await service.getZertifizierungById(id))
        })
    }

    const aendereBemerkungAnDieLandesstelle = async (
        request: AendereBemerkungAnDieLandesstelleRequest
    ) => {
        return setLoading('aendereBemerkungAnDieLandesstelle', () =>
            service.aendereBemerkungAnDieLandesstelle(request)
        )
    }

    const nehmeOrdnungspruefungVor = async (request: NehmeOrdnungspruefungVorRequest) => {
        return setLoading('nehmeOrdnungspruefungVor', () =>
            service.nehmeOrdnungspruefungVor(request)
        )
    }

    const markiereBeurkundungAlsVersendet = async (
        request: MarkiereBeurkundungAlsVersendetRequest
    ) => {
        return setLoading('markiereBeurkundungAlsVersendet', () =>
            service.markiereBeurkundungAlsVersendet(request)
        )
    }

    const markiereRechnungAlsVersendet = async (request: MarkiereRechnungAlsVersendetRequest) => {
        return setLoading('markiereRechnungAlsVersendet', () =>
            service.markiereRechnungAlsVersendet(request)
        )
    }

    return {
        zertifizierungen,
        loadingMethods,
        isLoading,
        isMethodLoading,
        addOrReplaceZertifizierung,
        getZertifizierungById,
        fetchZertifizierungById,
        aendereBemerkungAnDieLandesstelle,
        nehmeOrdnungspruefungVor,
        markiereBeurkundungAlsVersendet,
        markiereRechnungAlsVersendet
    }
})
