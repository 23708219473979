import { useFetchItems } from '@/composables'
import type {
    AendereKontaktOfBetriebsbeauftragterRequest,
    Betriebsbeauftragter,
    BetriebsbeauftragteResponse
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebsbeauftragterService = () => {
    const getAllBetriebsbeauftragte = (fetchParams: FetchParams = {}) => {
        const fetch = useFetchItems({ baseURL: '/api/betriebsbeauftragte' })

        return fetch.get<BetriebsbeauftragteResponse>({ fetchParams })
    }

    const getBetriebsbeauftragterByIri = (iri: Betriebsbeauftragter['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Betriebsbeauftragter>({})
    }

    const getBetriebsbeauftragterById = (id: string) => {
        return getBetriebsbeauftragterByIri(`/api/betriebsbeauftragte/${id}`)
    }

    const aendereKontaktOfBetriebsbeauftragter = async (
        request: AendereKontaktOfBetriebsbeauftragterRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebsbeauftragte/aendere-kontakt'
        }).post<Betriebsbeauftragter>({ body: request })
    }

    return {
        getAllBetriebsbeauftragte,
        getBetriebsbeauftragterById,
        getBetriebsbeauftragterByIri,
        aendereKontaktOfBetriebsbeauftragter
    }
}
