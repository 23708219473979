import {
    type BetriebsbeauftragterFilterSettings,
    type BetriebspruefungFilterSettings,
    type FachbetriebFilterSettings
} from '@/composables/settings'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useSettings = () => {
    const createFilter = <T>(key: string) => {
        const filter = useStorage<T | null>(key, null, localStorage, {
            serializer: StorageSerializers.object
        })

        return {
            load: (): T | null => filter.value,
            store: (newFilter: T) => {
                filter.value = newFilter
            }
        }
    }

    return {
        betriebspruefungFilter: createFilter<BetriebspruefungFilterSettings>(
            'settings.betriebspruefung.filter'
        ),
        fachbetriebFilter: createFilter<FachbetriebFilterSettings>('settings.fachbetrieb.filter'),
        betriebsbeauftragterFilter: createFilter<BetriebsbeauftragterFilterSettings>(
            'settings.betriebsbeauftragter.filter'
        )
    }
}
