import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Betriebsbeauftragter } from '@/js/model'
import { useBetriebsbeauftragterService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useBetriebsbeauftragterStore = defineStore('betriebsbeauftragter', () => {
    const betriebsbeauftragte = ref<Betriebsbeauftragter[]>([])
    const service = useBetriebsbeauftragterService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const getFullNameById = (id?: string | null): string => {
        return (
            betriebsbeauftragte.value.find((item) => item.betriebsbeauftragterId === id)
                ?.fullName ?? ''
        )
    }

    const getBetriebsbeauftragterById = (id: string): Betriebsbeauftragter | null => {
        const betriebsbeauftragter = betriebsbeauftragte.value.find(
            (item) => item.betriebsbeauftragterId === id
        )
        if (betriebsbeauftragter) {
            return betriebsbeauftragter
        } else {
            return null
        }
    }

    const fetchBetriebsbeauftragterById = async (id: string): Promise<void> => {
        await setLoading('fetch', async () => {
            addOrReplaceBetriebsbeauftragter(await service.getBetriebsbeauftragterById(id))
        })
    }

    const fetchBetriebsbeauftragterByIri = async (iri: string): Promise<void> => {
        await setLoading('fetch', async () => {
            addOrReplaceBetriebsbeauftragter(await service.getBetriebsbeauftragterByIri(iri))
        })
    }

    const addOrReplaceBetriebsbeauftragter = (betriebsbeauftragter: Betriebsbeauftragter): void => {
        const index = betriebsbeauftragte.value.findIndex(
            (item) => item.betriebsbeauftragterId === betriebsbeauftragter.betriebsbeauftragterId
        )

        if (index !== -1) {
            betriebsbeauftragte.value[index] = betriebsbeauftragter
        } else {
            betriebsbeauftragte.value.push(betriebsbeauftragter)
        }
    }

    return {
        betriebsbeauftragte,
        loadingMethods,
        isLoading,
        isMethodLoading,
        getBetriebsbeauftragterById,
        fetchBetriebsbeauftragterById,
        fetchBetriebsbeauftragterByIri,
        getFullNameById
    }
})
