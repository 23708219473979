import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Fachbetrieb } from '@/js/model'
import { useFachbetriebService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useFachbetriebStore = defineStore('fachbetrieb', () => {
    const fachbetriebe = ref<Fachbetrieb[]>([])
    const anzahlFachbetriebeOfFachpruefer = ref<{ [key: string]: number | undefined }>({})
    const service = useFachbetriebService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const fetchFachbetriebe = async () => {
        await setLoading('fetchFachbetriebe', async () => {
            const result = await service.getAllFachbetriebe()
            fachbetriebe.value = result['hydra:member']
        })
    }

    const fetchAnzahlFachbetriebeByFachprueferId = async (fachprueferId: string) => {
        await setLoading('fetchAnzahlFachbetriebeByFachprueferId', async () => {
            const result = await service.getAnzahlFachbetriebeByFachprueferId(fachprueferId)
            anzahlFachbetriebeOfFachpruefer.value[fachprueferId] = result?.['hydra:totalItems'] || 0
        })
    }

    const getAnzahlFachbetriebeByFachprueferId = (fachprueferId: string) => {
        return anzahlFachbetriebeOfFachpruefer.value[fachprueferId]
    }

    return {
        fachbetriebe,
        loadingMethods,
        isLoading,
        isMethodLoading,
        fetchFachbetriebe,
        fetchAnzahlFachbetriebeByFachprueferId,
        getAnzahlFachbetriebeByFachprueferId
    }
})
