<script setup lang="ts">
    import { type Betriebspruefung, type MangelDerPruefung, type Zertifizierung } from '@/js/model'
    import { computed, onMounted, ref } from 'vue'
    import { DokumentPreviewCell } from '@/components'
    import { useAuthStore, useZertifizierungStore } from '@/stores'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const showDialog = ref<boolean>(false)
    const selectedMangel = ref<MangelDerPruefung | undefined>(undefined)
    const mangelBeschreibung = ref<string>('')

    const authStore = useAuthStore()
    const zertifizierungStore = useZertifizierungStore()

    const zertifizierung = computed<Zertifizierung | null>(() => {
        return zertifizierungStore.getZertifizierungById(props.betriebspruefung.zertifizierungId)
    })

    const isUewg = computed(() => {
        return authStore.hasRole('Uewg')
    })

    const mangelLabel = computed(() => {
        switch (props.betriebspruefung.pruefungsergebnis.mangel?.mangelKey) {
            case 0:
                return '<nicht erfasst>'
            case 1:
                return 'kein Mangel'
            case 2:
                return 'geringfügiger Mangel'
            case 3:
                return 'mittlerer Mangel'
            case 4:
                return 'erheblicher Mangel'
            default:
                return ''
        }
    })

    async function setInitialValues() {
        selectedMangel.value = JSON.parse(
            JSON.stringify(props.betriebspruefung.pruefungsergebnis.mangel)
        )
        mangelBeschreibung.value = props.betriebspruefung.pruefungsergebnis.bemerkung
        if (!zertifizierung.value) {
            await zertifizierungStore.fetchZertifizierungById(
                props.betriebspruefung.zertifizierungId
            )
        }
    }

    onMounted(async () => {
        setInitialValues()
    })

    const handleDismiss = async () => {
        showDialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-btn
        v-if="betriebspruefung.isAbgeschlossen"
        variant="text"
        size="small"
        class="my-1"
        title="Verlauf der Betriebsprüfung anzeigen"
        @click="showDialog = true"
    >
        <v-icon>mdi-eye</v-icon>
    </v-btn>

    <v-dialog
        v-if="betriebspruefung.isAbgeschlossen"
        v-model="showDialog"
        max-width="1800"
        @after-leave="handleDismiss"
    >
        <v-card
            prepend-icon="mdi-file-outline"
            :title="`Ergebnis der Betriebsprüfung für ${betriebspruefung.firma.name} ${betriebspruefung.mitgliedsnummer}`"
        >
            <v-divider></v-divider>
            <v-card-subtitle>Status</v-card-subtitle>
            <v-card-text>
                <v-timeline side="end" density="comfortable">
                    <v-timeline-item dot-color="info" size="small">
                        <template v-slot:opposite>
                            <div
                                :class="`pt-1 headline font-weight-bold text-info`"
                                v-text="formatDate(betriebspruefung.terminAm)"
                            ></div>
                        </template>
                        <v-alert color="info" icon="mdi-information">
                            Betriebsprüfungstermin
                        </v-alert>
                    </v-timeline-item>
                    <v-timeline-item
                        :dot-color="betriebspruefung.isAbgeschlossen ? 'info' : 'warning'"
                        size="small"
                    >
                        <template v-slot:opposite>
                            <div
                                :class="`pt-1 headline font-weight-bold text-${betriebspruefung.isAbgeschlossen ? 'info' : 'warning'}`"
                                v-text="formatDate(betriebspruefung.abgeschlossenAt)"
                            ></div>
                        </template>
                        <v-alert
                            :color="`${betriebspruefung.isAbgeschlossen ? 'info' : 'white'}`"
                            :icon="`${betriebspruefung.isAbgeschlossen ? 'mdi-information' : 'mdi-timer-sand'}`"
                        >
                            Betriebsprüfung abgeschlossen
                        </v-alert>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="zertifizierung"
                        :dot-color="zertifizierung.isOrdnungspruefungVorgenommen ? 'info' : 'white'"
                        size="small"
                    >
                        <template v-slot:opposite>
                            <!--                            <div-->
                            <!--                                :class="`pt-1 headline font-weight-bold text-${zertifizierung.isOrdnungspruefungVorgenommen ? 'info' : 'warning'}`"-->
                            <!--                                v-text="formatDate(zertifizierung.ordnungspruefungVorgenommenAt)"-->
                            <!--                            ></div>-->
                        </template>
                        <v-alert
                            :color="`${zertifizierung.isOrdnungspruefungVorgenommen ? 'info' : 'warning'}`"
                            :icon="`${zertifizierung.isOrdnungspruefungVorgenommen ? 'mdi-information' : 'mdi-timer-sand'}`"
                        >
                            Ordnungsprüfung durchgeführt
                        </v-alert>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="zertifizierung && isUewg"
                        :dot-color="zertifizierung.isRechnungVersendet ? 'info' : 'white'"
                        size="small"
                    >
                        <template v-slot:opposite>
                            <!--                            <div-->
                            <!--                                :class="`pt-1 headline font-weight-bold text-${zertifizierung.isRechnungVersendet ? 'info' : 'warning'}`"-->
                            <!--                                v-text="formatDate(zertifizierung.rechnungVersendetAm)"-->
                            <!--                            ></div>-->
                        </template>
                        <v-alert
                            :color="`${zertifizierung.isRechnungVersendet ? 'info' : 'warning'}`"
                            :icon="`${zertifizierung.isRechnungVersendet ? 'mdi-information' : 'mdi-timer-sand'}`"
                        >
                            Rechnung versendet
                        </v-alert>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="zertifizierung"
                        :dot-color="
                            zertifizierung.isBeurkundungAlsVersendetMarkiert ? 'info' : 'white'
                        "
                        size="small"
                    >
                        <template v-slot:opposite>
                            <div
                                :class="`pt-1 headline font-weight-bold text-${zertifizierung.isBeurkundungAlsVersendetMarkiert ? 'info' : 'warning'}`"
                                v-text="formatDate(zertifizierung.beurkundungVersendetAm)"
                            ></div>
                        </template>
                        <v-alert
                            :color="`${zertifizierung.isBeurkundungAlsVersendetMarkiert ? 'info' : 'warning'}`"
                            :icon="`${zertifizierung.isBeurkundungAlsVersendetMarkiert ? 'mdi-information' : 'mdi-timer-sand'}`"
                        >
                            Urkunde versendet
                        </v-alert>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Grad des Mangels</v-card-subtitle>
            <v-card-text>{{ mangelLabel }}</v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Beschreibung des Mangels</v-card-subtitle>
            <v-card-text
                ><span v-html="betriebspruefung.pruefungsergebnis.bemerkung" class="nl2br"
            /></v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Bemerkung an die Landesstelle</v-card-subtitle>
            <v-card-text>
                <span v-html="zertifizierung?.bemerkungAnDieLandesstelle ?? '-'" class="nl2br" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Dokumente zur Betriebsprüfung</v-card-subtitle>
            <v-container fluid>
                <v-row>
                    <v-col v-for="dokumentId in betriebspruefung.dokumentIds" :key="dokumentId">
                        <DokumentPreviewCell
                            :dokument-id="dokumentId"
                            :is-with-delete="false"
                            :key="dokumentId"
                            class="grid"
                        />
                    </v-col>
                    <v-col v-if="!betriebspruefung.dokumentIds.length">
                        Noch keine Dokumente hinzugefügt.
                    </v-col>
                </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    block
                    text="OK"
                    variant="tonal"
                    @click="handleDismiss"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
