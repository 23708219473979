<script setup lang="ts">
    import { type Betriebspruefung, type LegeBetriebspruefungsterminFestRequest } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { formatDateTimeNoSeconds } from '@/utils'
    import { computed, onMounted, ref, watch } from 'vue'
    import { useAuthStore } from '@/stores'
    import dayjs from 'dayjs'
    import { TerminAmButton } from '@/components'

    const authStore = useAuthStore()
    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const service = useBetriebspruefungService()

    const dialog = ref<boolean>(false)
    const terminAm = ref<Date | null>(null)
    const terminBemerkung = ref<string>('')
    const isAngefragt = ref<boolean>(false)
    const isBestaetigt = ref<boolean>(false)
    const isSendMail = ref<boolean>(true)
    const isSendCopy = ref<boolean>(true)
    const isAngefragtDisabled = computed(() => {
        return isBestaetigt.value
    })
    const isSendEmailDisabled = computed(() => {
        return !isBestaetigt.value
    })
    const isSendCopyDisabled = computed(() => {
        return !isSendMail.value || isSendEmailDisabled.value
    })
    const isSubmitDisabled = computed(() => {
        const format = 'YYYYMMDDHHmm'

        return (
            dayjs(props.betriebspruefung.terminAm).format(format) ===
                dayjs(terminAm.value).format(format) &&
            '' === terminBemerkung.value &&
            props.betriebspruefung.isTerminAngefragt === isAngefragt.value &&
            props.betriebspruefung.isTerminBestaetigt === isBestaetigt.value
        )
    })

    watch([isBestaetigt], () => {
        if (isBestaetigt.value) {
            isAngefragt.value = true
        }
    })

    watch([isSendMail], () => {
        if (!isSendMail.value) {
            isSendCopy.value = false
        }
    })

    function setInitialValues() {
        terminBemerkung.value = ''
        isAngefragt.value = props.betriebspruefung.isTerminAngefragt
        isBestaetigt.value = props.betriebspruefung.isTerminBestaetigt
        if (props.betriebspruefung.terminAm) {
            terminAm.value = new Date(props.betriebspruefung.terminAm)
        }
    }

    onMounted(async () => {
        setInitialValues()
    })

    const handleTerminAmSubmit = async () => {
        if (terminAm.value) {
            const request: LegeBetriebspruefungsterminFestRequest = {
                betriebspruefung: props.betriebspruefung['@id'],
                terminAm: dayjs(terminAm.value).toISOString(),
                terminBemerkung: terminBemerkung.value,
                isTerminAngefragt: isAngefragt.value,
                isTerminBestaetigt: isBestaetigt.value,
                isSendEmail: isSendMail.value && !isSendEmailDisabled.value,
                isSendCopy: isSendCopy.value && !isSendCopyDisabled.value
            }

            const result = await service.legeBetriebspruefungsterminFest(request)

            if (result) {
                emit('itemChanged', result)
                dialog.value = false
            }
        }
    }

    const handleAbort = async () => {
        setInitialValues()
        dialog.value = false
    }

    const isFachpruefer = computed(() => {
        return authStore.hasRole('Fachpruefer')
    })
    const isAllowedToAccessBemerkung = computed(() => {
        return isFachpruefer.value
    })

    const hasTerminBemerkung = computed(() => {
        return (
            props.betriebspruefung.terminBemerkung && props.betriebspruefung.terminBemerkung !== ''
        )
    })
</script>

<template>
    <template v-if="betriebspruefung.isAbgeschlossen || !isFachpruefer">{{
        formatDateTimeNoSeconds(betriebspruefung.terminAm)
    }}</template>
    <v-dialog v-else v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <TerminAmButton :betriebspruefung="betriebspruefung" v-bind="activatorProps" />
        </template>
        <v-card prepend-icon="mdi-calendar" title="Terminvereinbarung">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">
                        <VueDatePicker
                            v-model="terminAm"
                            :week-numbers="{ type: 'iso' }"
                            placeholder="Termin wählen"
                            :clearable="false"
                            data-test="terminvereinbarung-input"
                            required
                            time-picker-inline
                            :minutes-increment="5"
                            locale="de"
                            format="dd.LL.y HH:mm"
                            cancel-text="abbrechen"
                            select-text="auswählen"
                            :teleport="true"
                            six-weeks="center"
                            :action-row="{ showPreview: false }"
                        />
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isAngefragt"
                            :label="`Termin ist angefragt`"
                            :disabled="isAngefragtDisabled"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isBestaetigt"
                            :label="`Termin ist bestätigt`"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row dense v-if="isAllowedToAccessBemerkung">
                    <v-col cols="12" md="12" sm="12">
                        <v-text-field
                            v-model="terminBemerkung"
                            placeholder="3x angerufen am 12.02.2024"
                            label="Bemerkung zur Terminvergabe (optional)"
                            hint="Die Bemerkung wird zu den bisherigen Bemerkungen hinzugefügt"
                            persistent-hint
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-card v-if="hasTerminBemerkung">
                            <v-card-text>
                                <b>Bisherige Bemerkungen:</b>
                                <div v-html="betriebspruefung.terminBemerkung" class="nl2br"
                            /></v-card-text>
                        </v-card>
                        <div v-else>
                            <v-icon class="mx-1">mdi-information-variant-circle</v-icon>
                            Es sind noch keine Bemerkungen vorhanden.
                        </div>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">
                        <span>
                            <b>Terminbestätigung per E-Mail:</b>
                        </span>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isSendMail"
                            :label="`E-Mail an den Fachbetrieb`"
                            :disabled="isSendEmailDisabled"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <v-switch
                            v-model="isSendCopy"
                            :label="`Kopie an mich`"
                            :disabled="isSendCopyDisabled"
                            color="primary"
                            dense
                            hide-details
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleTerminAmSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
