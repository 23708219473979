import { ref, computed } from 'vue'

export function useLoading() {
    const loadingMethods = ref<Set<string>>(new Set())

    const isLoading = computed(() => loadingMethods.value.size > 0)

    const isMethodLoading = (methodName: string): boolean => {
        return loadingMethods.value.has(methodName)
    }

    const setLoading = async <T>(methodName: string, fn: () => Promise<T>): Promise<T> => {
        loadingMethods.value.add(methodName)
        try {
            return await fn()
        } finally {
            loadingMethods.value.delete(methodName)
        }
    }

    return {
        isLoading,
        isMethodLoading,
        loadingMethods,
        setLoading
    }
}
