import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Landesstelle } from '@/js/model'
import { useLandesstellenService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useLandesstellenStore = defineStore('landesstellen', () => {
    const landesstellen = ref<Landesstelle[]>([])
    const service = useLandesstellenService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const getNameById = (id: Landesstelle['landesstelleId']): string => {
        return landesstellen.value.find((item) => item.landesstelleId === id)?.name ?? ''
    }

    const getNamesByIds = (ids: Array<Landesstelle['landesstelleId']>): string => {
        const matchingItems = landesstellen.value
            .filter((item) => ids.includes(item.landesstelleId))
            .filter((item) => !!item.name)
            .map((item) => item.name)
        if (matchingItems.length > 0) {
            return matchingItems.join(', ')
        }
        return ''
    }

    const fetchLandesstellen = async (): Promise<void> => {
        await setLoading('fetch', async () => {
            const result = await service.getAllLandesstellen()
            landesstellen.value = result['hydra:member']
        })
    }

    return {
        landesstellen,
        loadingMethods,
        isLoading,
        isMethodLoading,
        fetchLandesstellen,
        getNameById,
        getNamesByIds
    }
})
