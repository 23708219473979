<script setup lang="ts">
    import { computed, ref } from 'vue'
    import { UserMenu } from '@/components/index'
    import {
        useAuthStore,
        useBetriebsbeauftragterStore,
        useBetriebspruefungStore,
        useDokumentStore,
        useFachbetriebStore,
        useFachprueferStore,
        useLandesstellenStore,
        useUserStore,
        useZertifizierungStore
    } from '@/stores'

    const authStore = useAuthStore()
    const betriebsbeauftragterStore = useBetriebsbeauftragterStore()
    const betriebspruefungStore = useBetriebspruefungStore()
    const dokumentStore = useDokumentStore()
    const fachbetriebStore = useFachbetriebStore()
    const fachprueferStore = useFachprueferStore()
    const landesstellenStore = useLandesstellenStore()
    const userStore = useUserStore()
    const zertifizierungStore = useZertifizierungStore()

    const drawer = ref(false)

    const canSeeFachprueferLink = computed(
        () => authStore.hasRole('Uewg') || authStore.hasRole('Landesstelle')
    )
    const canSeeUserListLink = computed(() => authStore.hasRole('Uewg'))

    const isLoading = computed(() => {
        return (
            authStore.isLoading ||
            betriebsbeauftragterStore.isLoading ||
            betriebspruefungStore.isLoading ||
            dokumentStore.isLoading ||
            fachbetriebStore.isLoading ||
            fachprueferStore.isLoading ||
            landesstellenStore.isLoading ||
            userStore.isLoading ||
            zertifizierungStore.isLoading
        )
    })
</script>

<template>
    <v-app-bar v-if="authStore.token" class="app-bar" color="primary" dark>
        <v-container class="d-flex align-center justify-space-between">
            <div class="d-flex align-center menu-icons">
                <v-app-bar-title>Vera</v-app-bar-title>

                <v-app-bar-nav-icon
                    @click="drawer = !drawer"
                    class="d-lg-none"
                ></v-app-bar-nav-icon>
            </div>

            <div class="d-none d-lg-flex justify-center flex-grow-1">
                <RouterLink
                    :to="{ name: 'betriebspruefungen' }"
                    class="nav-item nav-link"
                    data-test="betriebspruefungen-nav"
                >
                    Betriebsprüfungen
                </RouterLink>
                <RouterLink
                    :to="{ name: 'fachbetriebe' }"
                    class="nav-item nav-link"
                    data-test="fachbetriebe-nav"
                >
                    Fachbetriebe
                </RouterLink>
                <RouterLink
                    :to="{ name: 'betriebsbeauftragte' }"
                    class="nav-item nav-link"
                    data-test="betriebsbeauftragte-nav"
                >
                    Betriebsbeauftragte
                </RouterLink>
                <RouterLink
                    v-if="canSeeFachprueferLink"
                    :to="{ name: 'fachpruefer' }"
                    class="nav-item nav-link"
                    data-test="fachpruefer-nav"
                >
                    Fachprüfer
                </RouterLink>
                <RouterLink
                    v-if="canSeeUserListLink"
                    :to="{ name: 'users' }"
                    class="nav-item nav-link"
                    data-test="benutzer-nav"
                >
                    Benutzer
                </RouterLink>
            </div>

            <div class="d-flex align-center menu-icons">
                <v-menu v-if="authStore.hasRole('Uewg')">
                    <template v-slot:activator="{ props: activatorProps }">
                        <v-btn
                            v-bind="activatorProps"
                            icon="mdi-shield-account-variant"
                            data-test="shield-nav"
                        />
                    </template>
                    <v-list>
                        <v-list-item link :to="{ name: 'logView' }">
                            <v-list-item-title data-test="logs-nav">Logs</v-list-item-title>
                        </v-list-item>
                        <v-list-item link :to="{ name: 'importedFileView' }">
                            <v-list-item-title data-test="imported-file-nav"
                                >Importierte Dateien
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <div class="ml-1" data-test="nav-user-item">
                    <UserMenu></UserMenu>
                </div>
            </div>
        </v-container>
        <v-progress-linear
            :active="isLoading"
            :indeterminate="isLoading"
            color="info"
            absolute
            bottom
        />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary color="#454545" dark>
        <v-list>
            <v-list-item link :to="{ name: 'betriebspruefungen' }">
                <v-list-item-title class="drawer-item">Betriebsprüfungen</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'fachbetriebe' }">
                <v-list-item-title class="drawer-item">Fachbetriebe</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'betriebsbeauftragte' }">
                <v-list-item-title class="drawer-item">Betriebsbeauftragte</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canSeeUserListLink" link :to="{ name: 'fachpruefer' }">
                <v-list-item-title class="drawer-item">Fachprüfer </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canSeeUserListLink" link :to="{ name: 'users' }">
                <v-list-item-title class="drawer-item">Benutzer</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<style scoped>
    .app-bar {
        max-width: 2400px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .nav-item {
        margin-left: 16px;
        margin-right: 16px;
    }

    .nav-link {
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.3s;
    }

    .nav-link:hover {
        color: #ffeb3b; /* Yellow color on hover */
    }

    .drawer-item {
        color: #ffffff;
        font-weight: bold;
    }

    .router-link-exact-active {
        border-bottom: 0.2rem solid #f4f4f9;
    }
</style>
