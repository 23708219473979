import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Dokument } from '@/js/model'
import { useBetriebspruefungService, useDokumentService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useDokumentStore = defineStore('dokument', () => {
    const dokumente = ref<Dokument[]>([])
    const service = useDokumentService()
    const betriebspruefungService = useBetriebspruefungService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const getDokumentById = (id: string): Dokument | null => {
        const dokument = dokumente.value.find((item) => item.dokumentId === id)
        if (dokument) {
            return dokument
        } else {
            return null
        }
    }

    const fetchDokumentById = async (id: string): Promise<void> => {
        await setLoading('fetch', async () => {
            addOrReplaceDokument(await service.getDokumentById(id))
        })
    }

    const entferneBetriebspruefungsdokument = async (dokument: Dokument): Promise<void> => {
        await setLoading('entferneBetriebspruefungsdokument', async () => {
            await betriebspruefungService.entferneBetriebspruefungsdokument({
                dokument: dokument['@id'],
                betriebspruefung: `/api/betriebspruefungen/${dokument.betriebspruefungId}`
            })
            removeDokument(dokument.dokumentId)
        })
    }

    const addOrReplaceDokument = (dokument: Dokument): void => {
        const index = dokumente.value.findIndex((item) => item.dokumentId === dokument.dokumentId)

        if (index !== -1) {
            dokumente.value[index] = dokument
        } else {
            dokumente.value.push(dokument)
        }
    }

    const removeDokument = (dokumentId: Dokument['dokumentId']): void => {
        const index = dokumente.value.findIndex((item) => item.dokumentId === dokumentId)

        if (index !== -1) {
            dokumente.value.splice(index, 1)
        }
    }

    return {
        dokumente,
        loadingMethods,
        isLoading,
        isMethodLoading,
        getDokumentById,
        entferneBetriebspruefungsdokument,
        fetchDokumentById
    }
})
