import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Fachpruefer } from '@/js/model'
import { useFachprueferService } from '@/services'
import { useLandesstellenStore } from '@/stores'
import { useLoading } from '@/composables/useLoading'

export const useFachprueferStore = defineStore('fachpruefer', () => {
    const fachpruefende = ref<Fachpruefer[]>([])
    const service = useFachprueferService()
    const landesstellenStore = useLandesstellenStore()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const getFullNameById = (id?: string | null): string => {
        return fachpruefende.value.find((item) => item.fachprueferId === id)?.fullName ?? ''
    }

    const fetchFachpruefende = async (): Promise<void> => {
        await setLoading('fetch', async () => {
            const result = await service.getAllFachpruefende()
            fachpruefende.value = result['hydra:member']
        })
    }

    const getLandesstelleByFachprueferId = (id: string) => {
        const fp = getFachprueferById(id)
        if (fp) {
            return landesstellenStore.getNamesByIds(fp.landesstelleIds)
        } else {
            return ''
        }
    }

    const getFachprueferById = (id: string): Fachpruefer | null => {
        const fachpruefer = fachpruefende.value.find((item) => item.fachprueferId === id)
        return undefined !== fachpruefer ? fachpruefer : null
    }

    return {
        fachpruefende,
        loadingMethods,
        isLoading,
        isMethodLoading,
        fetchFachpruefende,
        getFachprueferById,
        getFullNameById,
        getLandesstelleByFachprueferId
    }
})
