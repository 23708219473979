<script setup lang="ts">
    import { computed, nextTick, ref, watch } from 'vue'
    import FilePreview from '@/components/FilePreview.vue'
    import { moveArrayElement, useSortable } from '@vueuse/integrations/useSortable'

    const emit = defineEmits<{
        orderChanged: [event: File[]]
    }>()

    const props = defineProps<{
        files: File[]
        disabled: boolean
    }>()

    const elem = ref<HTMLElement | null>(null)
    const liste = ref<{ id: number; value: File }[]>([])

    const isDisabled = computed(() => {
        return props.disabled
    })

    useSortable(elem, liste, {
        animation: 150,
        onUpdate: (e) => {
            if (isDisabled) {
                return
            }
            moveArrayElement(liste.value, e.oldIndex!, e.newIndex!)
            // nextTick required here as moveArrayElement is executed in a microtask
            // so we need to wait until the next tick until that is finished.
            nextTick(() => {
                let files: File[] = []
                liste.value.forEach((item) => {
                    files.push(item.value)
                })
                emit('orderChanged', files)
            })
        }
    })

    const removeItem = (index: number) => {
        liste.value.splice(index, 1)

        let files: File[] = []
        liste.value.forEach((item) => {
            files.push(item.value)
        })
        emit('orderChanged', files)
    }

    watch(
        () => props.files,
        (files) => {
            let newFiles: { id: number; value: File }[] = []
            files.forEach((file, key) => {
                newFiles.push({
                    id: key,
                    value: file
                })
            })
            liste.value = [...newFiles]
        },
        { immediate: true }
    )
</script>

<template>
    <v-row ref="elem">
        <v-col
            v-for="(file, key) in liste"
            class="d-flex child-flex"
            :key="file.id"
            cols="12"
            sm="4"
            md="4"
            lg="3"
        >
            <v-badge
                color="success"
                :content="key + 1"
                location="left top"
                class="files-cell--draggable"
            >
                <v-card class="d-flex flex-column">
                    <FilePreview v-if="file" :file="file.value" />
                </v-card>
                <v-avatar v-if="!isDisabled" inline color="primary" size="24" class="ml-n3 mt-n3">
                    <v-icon small dark style="z-index: 20001" @click="removeItem(key)"
                        >mdi-close</v-icon
                    >
                </v-avatar>
            </v-badge>
        </v-col>
    </v-row>
</template>
<style scoped>
    .files-cell--draggable {
        cursor: move;
    }
</style>
