<script setup lang="ts">
    import { onBeforeMount, ref } from 'vue'
    import { useFachprueferStore } from '@/stores'
    import { unionBy } from '@/utils'

    export type FachprueferFilterEvent = {
        all: boolean
        selection: string[]
    }

    const emit = defineEmits<{
        selectionChanged: [event: FachprueferFilterEvent]
    }>()

    const fachprueferStore = useFachprueferStore()
    const fachprueferFilter = ref<string[]>([])
    const fachprueferFilterOptions = ref<{ title: string; value: string }[]>([])
    const selectAllFachpruefende = ref(true)

    const toggleSelectAllFachpruefende = () => {
        if (selectAllFachpruefende.value) {
            selectAllFachpruefende.value = false
            onFilterFachpruefende(fachprueferFilter.value)
        } else {
            selectAllFachpruefende.value = true
            fachprueferFilter.value = []
            onFilterFachpruefende([])
        }
    }

    const onFilterFachpruefende = (newFilter: string[]) => {
        if (newFilter.length > 0) {
            selectAllFachpruefende.value = false
        }
        emit('selectionChanged', {
            all: selectAllFachpruefende.value,
            selection: fachprueferFilter.value
        })
    }

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })

    fachprueferStore.$subscribe((_, state) => {
        console.log('state.fachpruefende')
        console.log(state.fachpruefende)
        const newOptions = state.fachpruefende.map((item) => ({
            title: `${item.nachname}, ${item.vorname}`,
            value: item.fachprueferId
        }))
        fachprueferFilterOptions.value = unionBy(
            fachprueferFilterOptions.value,
            newOptions,
            'value'
        )
    })
</script>

<template>
    <v-select
        density="compact"
        v-model="fachprueferFilter"
        :items="fachprueferFilterOptions"
        label="Nach Fachprüfer filtern..."
        data-test="fachpruefer-filter"
        multiple
        hide-details
        @update:modelValue="onFilterFachpruefende"
    >
        <template v-slot:prepend-item>
            <v-list-item title="Alle auswählen" @click="toggleSelectAllFachpruefende">
                <template v-slot:prepend>
                    <v-checkbox-btn :model-value="selectAllFachpruefende"></v-checkbox-btn>
                </template>
            </v-list-item>

            <v-divider class="mt-2"></v-divider>
        </template>
    </v-select>
</template>

<style scoped></style>
