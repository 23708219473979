import { defineStore } from 'pinia'
import { ref } from 'vue'
import type {
    AendereBemerkungOfBetriebspruefungRequest,
    AendereErgebnisOfBetriebspruefungRequest,
    Betriebspruefung,
    EntferneBetriebspruefungsdokumentRequest,
    FuegeBetriebspruefungsdokumentHinzuRequest,
    LegeBetriebspruefungsterminFestRequest,
    SchliesseBetriebspruefungAbRequest,
    Zertifizierung
} from '@/js/model'
import { useBetriebspruefungService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useBetriebspruefungStore = defineStore('betriebspruefung', () => {
    const betriebspruefungen = ref<Betriebspruefung[]>([])
    const service = useBetriebspruefungService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const addOrReplaceBetriebspruefung = (betriebspruefung: Betriebspruefung): void => {
        const index = betriebspruefungen.value.findIndex(
            (item) => item.betriebspruefungId === betriebspruefung.betriebspruefungId
        )

        if (index !== -1) {
            betriebspruefungen.value[index] = betriebspruefung
        } else {
            betriebspruefungen.value.push(betriebspruefung)
        }
    }

    const getBetriebspruefungById = (id: string): Betriebspruefung | null => {
        const betriebspruefung = betriebspruefungen.value.find(
            (item) => item.betriebspruefungId === id
        )
        if (betriebspruefung) {
            return betriebspruefung
        } else {
            return null
        }
    }

    const fetchBetriebspruefungById = async (id: string) => {
        await setLoading('fetchBetriebspruefungById', async () => {
            addOrReplaceBetriebspruefung(await service.getBetriebspruefungById(id))
        })
    }

    const aendereErgebnisOfBetriebspruefung = async (
        request: AendereErgebnisOfBetriebspruefungRequest
    ) => {
        return setLoading('aendereErgebnisOfBetriebspruefung', () => {
            return service.aendereErgebnisOfBetriebspruefung(request)
        })
    }

    const aendereBemerkungOfBetriebspruefung = async (
        request: AendereBemerkungOfBetriebspruefungRequest
    ) => {
        return setLoading('aendereBemerkungOfBetriebspruefung', () => {
            return service.aendereBemerkungOfBetriebspruefung(request)
        })
    }

    const schliesseBetriebspruefungAb = async (request: SchliesseBetriebspruefungAbRequest) => {
        return setLoading('schliesseBetriebspruefungAb', () => {
            return service.schliesseBetriebspruefungAb(request)
        })
    }

    const legeBetriebspruefungsterminFest = async (
        request: LegeBetriebspruefungsterminFestRequest
    ) => {
        return setLoading('legeBetriebspruefungsterminFest', () => {
            return service.legeBetriebspruefungsterminFest(request)
        })
    }

    const fuegeBetriebspruefungsdokumentHinzu = async (
        request: FuegeBetriebspruefungsdokumentHinzuRequest
    ) => {
        return setLoading('fuegeBetriebspruefungsdokumentHinzu', () => {
            return service.fuegeBetriebspruefungsdokumentHinzu(request)
        })
    }

    const entferneBetriebspruefungsdokument = async (
        request: EntferneBetriebspruefungsdokumentRequest
    ) => {
        return setLoading('entferneBetriebspruefungsdokument', () => {
            return service.entferneBetriebspruefungsdokument(request)
        })
    }

    return {
        betriebspruefungen,
        loadingMethods,
        isLoading,
        isMethodLoading,
        getBetriebspruefungById,
        fetchBetriebspruefungById,
        aendereErgebnisOfBetriebspruefung,
        schliesseBetriebspruefungAb,
        legeBetriebspruefungsterminFest,
        fuegeBetriebspruefungsdokumentHinzu,
        entferneBetriebspruefungsdokument
    }
})
